
import { commitSetCartSidebarShow } from '@/store/main/mutations';
import { dispatchGetCompareProductsList, dispatchGetInvoiceDetails, dispatchRequestProductPurchase, dispatchToggleProductInCart } from '@/store/products/actions';
import { readInvoiceDetail, readProductsInvoice } from '@/store/products/getters';
import { commitSetCompareCount, commitSetProductsInvoice } from '@/store/products/mutations';
import { getLocalCompareProducts, getLocalSessionId, saveLocalCompareProducts } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import {LMap, LTileLayer} from 'vue2-leaflet';

@Component({
  components: {
    LMap,
    LTileLayer,
  },
})

export default class CompareList extends Vue {
  public compareTable: any = {};
  public compareList: number[] = [];
  
  public async mounted() {
    let compareList = getLocalCompareProducts();
    this.compareList = compareList ? JSON.parse(compareList) : [];
    this.compareTable = await dispatchGetCompareProductsList(
      this.$store, {compare_products_list: this.compareList},
    );
  }

  public async handleAddToCart(id) {
    let inCart: any = null;
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        if (this.invoiceDetails.items[i].object_id === id) {
          inCart = {
            id: this.invoiceDetails.items[i].id,
            quantity: this.invoiceDetails.items[i].quantity,
          };
        }
      }
    }

    if (inCart) {
      await dispatchToggleProductInCart(this.$store, {invoice_item: inCart.id, action: 'add'});
    } else {
      const response = await dispatchRequestProductPurchase(
        this.$store, {
          product_property: id,
          quantity: 1,
          invoice: this.productsInvoice ? this.productsInvoice : null,
          anon_session_id: getLocalSessionId(),
        },
      );
      commitSetProductsInvoice(this.$store, response);
      if (this.productsInvoice) {
        await dispatchGetInvoiceDetails(this.$store, {invoice_id: this.productsInvoice, anon_session_id: getLocalSessionId(),});
      }
      commitSetCartSidebarShow(this.$store, true);
    }
    // @ts-ignore
    this.$toast.success(this.$t('Добавлено в корзину'), {
      duration: 3000, position: 'bottom-right',
    });
    // @ts-ignore
    // this.animateElement(`added-to-cart-amount-${this.product.id}`, 'animate__jello');
  }

  public async handleRemoveFromCart(id) {
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        if (this.invoiceDetails.items[i]) {
          if (this.invoiceDetails.items[i].object_id === id) {
            await dispatchToggleProductInCart(
              this.$store, {invoice_item: this.invoiceDetails.items[i].id, action: 'remove'},
            );
          }
        }
      }
    }
  }

  public quantityOfProductInCart(id) {
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        if (this.invoiceDetails.items[i]) {
          if (this.invoiceDetails.items[i].object_id === id) {
            return this.invoiceDetails.items[i].quantity;
          }
        }
      }
    }
    return 0;
  }

  public async toggleProductInCompareList(id) {
    if (this.compareList.includes(id)) {
      let compareList = this.compareList;
      const index = compareList.indexOf(id);
      if (index > -1) {
        compareList.splice(index, 1);
      }
      saveLocalCompareProducts(JSON.stringify(compareList));
    }
    let compareList = getLocalCompareProducts();
    this.compareList = compareList ? JSON.parse(compareList) : [];
    commitSetCompareCount(this.$store, this.compareList.length);
    this.compareTable = await dispatchGetCompareProductsList(
      this.$store, {compare_products_list:compareList ? JSON.parse(compareList) : []},
    );
  }

  public async clearList() {
    saveLocalCompareProducts(JSON.stringify([]));
    this.compareTable = await dispatchGetCompareProductsList(
      this.$store, {compare_products_list: []},
    );
    this.compareList = [];
    commitSetCompareCount(this.$store, this.compareList.length);
  }

  get invoiceDetails() {
    return readInvoiceDetail(this.$store);
  }

  get productsInvoice() {
    return readProductsInvoice(this.$store);
  }
}
